import ExpImg1 from "../assets/images/resources/isenmy.jpeg";
import ExpImg2 from "../assets/images/resources/lmf.jpeg";
import ExpImg3 from "../assets/images/resources/showcaseinterior.jpeg";
import ExpImg4 from "../assets/images/resources/1593080642369.jpeg";
import ExpImg5 from "../assets/images/resources/Flow Module.png";

export const workExp = [
  {
    expCount: "1",
    expDate: "2018 - Present",
    expImg: ExpImg1,
    expTitle: "ISEN MY",
    expSubTitle: "(Freelance Project)",
    expDesc:
      "ISEN MY is education specialist company. I am working on the landing page for the company.",
  },
  {
    expCount: "2",
    expDate: "2019 - Present",
    expImg: ExpImg2,
    expTitle: "LMF Academy Project",
    expSubTitle: "(Freelance Project)",
    expDesc:
      "LMF Academy is an language educaiton center. I am working on the landing page & the booking system for the company.",
  },
  {
    expCount: "3",
    expDate: "2020 - 2021",
    expImg: ExpImg3,
    expTitle: "Showcase Interior",
    expSubTitle: "(Business Project)",
    expDesc:
      "This project allow the client to find the right interior design company and the company can get leads based on their project showcase in the platform.",
  },
  {
    expCount: "4",
    expDate: "2020 - 2020",
    expImg: ExpImg4,
    expTitle: "Vitrox's Solutions Engineer",
    expSubTitle: "(Internship)",
    expDesc:
      "Giving advice on technology parts to the customer and the team. As well as sharing my past experience, so that the team can grow easily. I have been working on the V-ONE platform, most of the dashboard is custom code using (HTML,CSS and Javascript) and web scraping for demo data as well.",
  },
  {
    expCount: "5",
    expDate: "2020 - 2023",
    expImg: ExpImg5,
    expTitle: "Gereatech's Staff Engineer (Lead)",
    expSubTitle: "(Full Time Employee)",
    expDesc:
      "Created roughly 20+ internal projects with the team. Guiding the team and in 2022 actually started the first company's trademark 'Xtitude' for data visualization platform.",
  },
  {
    expCount: "6",
    expDate: "2023 - 2023",
    expImg: ExpImg5,
    expTitle: "Wondernica's Chief Web Developer",
    expSubTitle: "(Full Time/Business)",
    expDesc:
      "Revamp the whole platform of 'Genesis' and created new brand for the company call 'Engiro' for the agriculture industry.",
  },
];
