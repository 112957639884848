import { ReactComponent as LinkedInIcon } from "../lib/icons/icons8-linkedin-circled-50.svg";
import { ReactComponent as TumblrIcon } from "../lib/icons/Tumblr.svg";
import { ReactComponent as TwitterIcon } from "../lib/icons/Twitter.svg";

export const social4 = [
  {
    socialIcon: (
      <LinkedInIcon className="h-5 w-5 lg:h-10 lg:w-10 fill-accent hover:fill-accent"></LinkedInIcon>
    ),
    socialLink: "https://www.linkedin.com/in/derrick-tpl/",
    socialTitle: "LinkedIn",
  },
];
