export const education = [
  {
    eduDate: "2016 - 2018",
    eduTitle: "Diploma in Computer Science",
    eduIns: "UOW KDU University College",
    eduDesc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    eduDate: "2018 - 2020",
    eduTitle: "Bachelor's Degree, CS",
    eduIns: "UOW KDU University College",
    eduDesc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];
